import React, {useContext} from "react";
import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";
import ReactPlayer from 'react-player/file'
import {
    GlobalDispatchContext
  } from "../../stores/GlobalContextProvider";
const gatsbyImage = true;

const MySwiper = React.memo(({ slides, workId}) => {
    const dispatch = useContext(GlobalDispatchContext);
    const { smallImgs } = useStaticQuery(
        graphql`
            query {
                smallImgs: allFile(filter: { ext: { regex: "/jpg/" } }) {
                    nodes {
                        name
                        childImageSharp {
                            fluid(maxWidth: 280, quality: 80, fit: INSIDE) {
                                ...GatsbyImageSharpFluid_noBase64
                            }
                        }
                    }
                }
            }
        `
    );

    const setFullView = (index) => {
        console.log('select slide', index);
        dispatch({ type: "SET_VIEW", payload: "full" });
        localStorage.setItem("view", "full");
        // dispatch({ type: "SET_CURRENT_ITEM", payload: workId });
        // dispatch({ type: "SET_SLIDE", payload: index });
        setTimeout(() => {
            document.getElementById("a" + workId).scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
        //     // dispatch({ type: "SET_CURRENT_ITEM", payload: null });
        //     // dispatch({ type: "SET_SLIDE", payload: null });
        }, 1000);
    }

    //const viewport = useWindowWidth();
    //let allImages = viewport.width > 1204 ? largeImgs.nodes : smallImgs.nodes;

    const renderSlide = (slide, index) => {
        const imageItem = fullName => {
            const imgUrl = fullName.substring(0, slide.indexOf("---"));
            const altText = fullName.substring(slide.indexOf("---") + 3);
            if (slide.indexOf(".jpg") > -1 && gatsbyImage) {
                const imgName = fullName.substring(8, slide.indexOf(".jpg"));
                const thisFluidSmall = smallImgs.nodes.find(item => item.name === imgName);
                if (thisFluidSmall && thisFluidSmall.childImageSharp && thisFluidSmall.childImageSharp.fluid) {
                    const sources = thisFluidSmall.childImageSharp.fluid;
                    return (
                        <Img
                            key={`abca${sources.media}`}
                            loading="lazy"
                            // fluid={thisFluid.childImageSharp.fluid}
                            fluid={sources}
                            imgStyle={{ objectFit: "contain" }}
                            className="thumbImage"
                            alt={altText}
                            title={altText}
                            placeholderClassName="placeholder"
                        />
                    );
                } else {
                    return <img key={`aab${index}`} src={imgUrl} alt={altText} title={altText} />;
                }
            } else if (slide.indexOf(".mp4") > -1) {
                return (
                    <ReactPlayer url={imgUrl} playsinline muted loop playing width="100%" height="100%" />
                );
            } else {
                return <img src={imgUrl} alt={altText} title={altText} />;
            }
        };

        if (slide) {
            return slide.indexOf("---") > -1 ? (
                <div
                    className="thumb"
                    key={`slide${index}`}
                    style={{ cursor: "pointer" }}
                    onClick={() => setFullView(index)}
                >
                    {imageItem(slide)}
                </div>
            ) : (
                <div
                    className="thumb"
                    key={`slide2${index}`}
                    style={{ cursor: "pointer" }}
                    onClick={() => setFullView(index)}
                >
                    <img src={slide} alt="" title="" />
                </div>
            );
        } else return "";
    };

    return <div className="thumbs">{slides && slides.map((slide, index) => renderSlide(slide, index))}</div>;
});
export const MyImageIndex = React.memo(MySwiper);
export default MyImageIndex;
