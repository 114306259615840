import React, {useContext} from "react";
import Img from "gatsby-image";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useStaticQuery, graphql } from "gatsby";
import ReactPlayer from 'react-player/file'
// import GlobalContext from "../../stores/GlobalContext";
//import useWindowWidth from "../useWindowWidth";
import {
    GlobalDispatchContext,
    GlobalStateContext,
  } from "../../stores/GlobalContextProvider";
import "swiper/swiper.scss";

const gatsbyImage = true;
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

const MySwiper = React.memo(({ slides }) => {
    const dispatch = useContext(GlobalDispatchContext);
    const globalState = useContext(GlobalStateContext);
    // let { globalState, dispatch } = React.useContext(GlobalContext);
    const { largeImgs, midImgs, smallImgs } = useStaticQuery(
        graphql`
            query {
                largeImgs: allFile(filter: { ext: { regex: "/jpg/" } }) {
                    nodes {
                        name
                        childImageSharp {
                            fluid(maxWidth: 1920, quality: 80, fit: INSIDE) {
                                ...GatsbyImageSharpFluid_noBase64
                            }
                        }
                    }
                }
                midImgs: allFile(filter: { ext: { regex: "/jpg/" } }) {
                    nodes {
                        name
                        childImageSharp {
                            fluid(maxWidth: 1280, quality: 80, fit: INSIDE) {
                                ...GatsbyImageSharpFluid_noBase64
                            }
                        }
                    }
                }
                smallImgs: allFile(filter: { ext: { regex: "/jpg/" } }) {
                    nodes {
                        name
                        childImageSharp {
                            fluid(maxWidth: 768, quality: 80, fit: INSIDE) {
                                ...GatsbyImageSharpFluid_noBase64
                            }
                        }
                    }
                }
            }
        `
    );
    //const viewport = useWindowWidth();
    //console.log("viewport.width", viewport.width);
    //let allImages = viewport.width > 1204 ? largeImgs.nodes : smallImgs.nodes;
    
    const renderSlide = (slide, index) => {
        const imageItem = (fullName) => {
            const imgUrl = fullName.substring(0, slide.indexOf("---"));
            const altText = fullName.substring(slide.indexOf("---") + 3);
            if (slide.indexOf(".jpg") > -1 && gatsbyImage) {
                const imgName = fullName.substring(8, slide.indexOf(".jpg"));
                const thisFluidSmall = smallImgs.nodes.find((item) => item.name === imgName);
                const thisFluidMiddle = midImgs.nodes.find((item) => item.name === imgName);
                const thisFluidLarge = largeImgs.nodes.find((item) => item.name === imgName);
                if (thisFluidSmall && thisFluidSmall.childImageSharp && thisFluidSmall.childImageSharp.fluid) {
                    const sources = [
                        {
                            ...thisFluidSmall.childImageSharp.fluid,
                            media: `(max-width: 768px)`,
                        },
                        {
                            ...thisFluidMiddle.childImageSharp.fluid,
                            media: `(min-width: 769px) and (max-width: 1280px)`,
                        },
                        {
                            ...thisFluidLarge.childImageSharp.fluid,
                            media: `(min-width: 1281px)`,
                        },
                    ];
                    //console.log(imgUrl, Math.random());
                    return (
                        <Img
                            // key={`smallImage${imgUrl}${sources.media}`}
                            loading="lazy"
                            // fluid={thisFluid.childImageSharp.fluid}
                            fluid={sources}
                            imgStyle={{ objectFit: "contain" }}
                            className="picture-container__image"
                            alt={altText}
                            title={altText}
                            placeholderClassName="placeholder"
                        />
                    );
                } else {
                    return <img 
                        // key={`defaultImage${imgUrl}${index}`} 
                        src={imgUrl} 
                        alt={altText} 
                        title={altText} />;
                }
            } else if (slide.indexOf(".mp4") > -1) {
                return (
                    <ReactPlayer url={imgUrl} playsinline muted loop playing width="100%" height="100%" />
                );
            } else {
                return <img src={imgUrl} alt={altText} title={altText} />;
            }
        };

        if (slide) {
            // console.log('myswiper slide', slide);
            return slide.indexOf("---") > -1 ? (
                <SwiperSlide key={`slide3${index}`}>
                    {/* <img src={slide.substring(0, slide.indexOf("---"))} alt={slide.substring(slide.indexOf("---") + 3)} title={slide.substring(slide.indexOf("---") + 3)} /> */}
                    {imageItem(slide)}
                </SwiperSlide>
            ) : (
                <SwiperSlide key={`slide4${index}`}>
                    <img src={slide} key={slide} alt="" title="" />
                </SwiperSlide>
            );
        } else return "";
    };

    return (
        <>
            {slides && (
                <Swiper
                    loop={slides.length > 1}
                    spaceBetween={-1}
                    speed={1234}
                    navigation={slides.length > 1}
                    slidesPerView={1}
                    // onSlideChange={(swiper) => {
                    //     if (globalState.workItem) {
                    //         const targetedArticle = swiper.$el.closest('#a' + globalState.workItem);
                    //         if (targetedArticle.length === 1) {
                    //             let active = globalState.slideItem + 1;
                    //             swiper.slideTo(active)
                    //         }
                    //     }
                    //     dispatch({ type: "SET_CURRENT_ITEM", payload: null });
                    //     dispatch({ type: "SET_SLIDE", payload: null });
                    //     console.log('workid', globalState.workItem, dispatch);
                    // }}
                >
                    {slides.map((slide, index) => renderSlide(slide, index))}
                </Swiper>
            )}
        </>
    );
});
export default MySwiper;
